import React from 'react';
import Layout from '../layout/Layout';

import { useForm } from "react-hook-form";


const DailyReport = () => {

  const ccForm = useForm();
  const { register, handleSubmit, setValue, getValues } = ccForm;
  const {
    daily_notes,
    daily_a_notes,
  } = getValues();

  const handleDailyReport = (data) => {
    console.log("DATA for William", data);
  }


  return (
    <Layout>
      <section className='section'>
        <div className='container'>
          <h2 className='title is-3 has-text-centered'>Close Mgt</h2>



          <form className='form' onSubmit={handleSubmit(handleDailyReport)}>
            <input
              type='hidden'
              name='jobid'
              ref={ register }
              defaultValue = { 'ttt' }
            />
            <div className='field'>
              <div className='control'>
                <label className="label" forhtml='cancel_notes'>Daily Report Notes</label>
                <textarea
                  className='textarea'
                  rows='2'
                  id='daily_notes'
                  name='daily_notes'
                  ref={ register }
                  placeholder="Daily Report notes..."
                />
              </div>
            </div><br/>

            <div className='field'>
              <div className='control'>
                <label className="label" forhtml='cancel_notes'>Daily Report Notes A</label>
                <textarea
                  className='textarea'
                  rows='2'
                  id='daily_a_notes'
                  name='daily_a_notes'
                  ref={ register }
                  placeholder="Daily Report A notes..."
                />
              </div>
            </div><br/>

            <div className='field'>
              <div className='control'>
                <label className="label" forhtml='cancel_notes'>Check truck cabs</label>
                <textarea
                  className='textarea'
                  rows='2'
                  id='daily_b_notes'
                  name='daily_b_notes'
                  ref={ register }
                  defaultValue = "[Check truck cabs]"
                />
              </div>
            </div><br/>

            <div className='has-text-centered'>
                <button name='cancel job' type='submit' className='button is-link is-rounded has-text-weight-bold'>Daily Report</button>
            </div>
          </form>

        </div>
        </section>
      </Layout>
      )



};

export default DailyReport;
